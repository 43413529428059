import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import cx from "classnames";
import StarIcon from "@svg/star.svg";
import { ModuleWrapper } from "../moduleWrapper";

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const Testimonial = ({ quote, stars, author, pageIndex }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="text-center"
      key={pageIndex}
    >
      <div className="flex justify-center gap-x-2 pb-2">
        {[...Array(stars)].map((_, index) => (
          <div key={index}>
            <StarIcon className="w-4 md:w-8" />
          </div>
        ))}
      </div>
      <h3 className="blockH1 max-w-6xl mx-auto">{quote}</h3>
      <h5 className="blockH4 font-medium mt-one">{author}</h5>
    </motion.div>
  );
};

const TestimonialCarousel = ({ config, items }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const wrappedIndex = wrap(0, items.length, pageIndex);

  return (
    <ModuleWrapper {...config}>
      <div className="px-gutter">
        <AnimatePresence exitBeforeEnter>
          <Testimonial {...items[wrappedIndex]} pageIndex={pageIndex} />
        </AnimatePresence>
        {items.length > 1 && (
          <div className="flex justify-center text-black mt-10 gap-x-2">
            {items.map((_, index) => (
              <span
                key={index}
                onClick={() => setPageIndex(index)}
                className={cx("pg-btn", {
                  selected: index === pageIndex,
                })}
              ></span>
            ))}
          </div>
        )}
      </div>
    </ModuleWrapper>
  );
};

export default TestimonialCarousel;
